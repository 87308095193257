import mixpanel from 'mixpanel-browser';
import { Stylist } from 'types/user';
import { Client } from 'types/user';
import { BodyType, Style } from 'types/utils';

import { extractUserDataForMixpanelPeople } from './utils';

const detectDeviceType = (width: number) => {
    if (width > 992) return 'desktop';
    else if (width < 992 && width > 567) return 'tablet';
    else return 'mobile';
};

export const initMixpanel = () => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            debug: process.env.NODE_ENV !== 'production'
        });
        mixpanel.register({
            Platform: 'web',
            Device: detectDeviceType(window.innerWidth)
        });
        mixpanel.track('Website Visits', {});
    }
};

export const trackEvent = (eventDetails: { name: string; properties: {} }) =>
    mixpanel.track(eventDetails.name, eventDetails.properties);

export const registerProperties = (propertiesDetails: {
    [key: string]: string | Style[] | Record<string, BodyType[]>;
}) => mixpanel.register(propertiesDetails);

export const isMatchedStylist = (stylistsList: Stylist[], stylistUUID: string | undefined) => {
    if (stylistUUID) {
        const matchedStylist = stylistsList.find((stylist) => stylist.uuid == stylistUUID);
        if (matchedStylist) return true;
        else return false;
    }
    return false;
};

export const setMixpanelPeopleData = (userData: Client) => {
    const currentPeopleData = extractUserDataForMixpanelPeople(userData);
    const uuid = currentPeopleData.UUID;

    if (uuid && mixpanel.get_distinct_id() !== uuid) {
        mixpanel.identify(uuid);
        mixpanel.people.set(currentPeopleData);
    }
};

export const resetMixpanelIdentity = () => mixpanel.reset();
