import { ActionTypes } from './types';
import ReduxService from 'services/redux-service';
import { Dispatch } from 'redux';
import { User, Stylist } from 'services';
import { getUserId, getUserToken } from 'services/utils/user-utils';
import { sentryException } from 'services/SentryLogging';

export const loadInboxStart = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.LOAD_INBOX_MESSAGES_REQUEST
    });
export const loadInbox = async (dispatch: Dispatch) => {
    const userId = getUserId();
    const token = getUserToken();
    await ReduxService.fetch({
        dispatch,
        targetAction: ActionTypes.LOAD_INBOX_MESSAGES,
        url: User.urls.inbox({ user_uuid: userId }),
        config: { headers: { token } }
    });
};

export const selectChannel = (dispatch: Dispatch, channel: string) =>
    dispatch({
        type: ActionTypes.SELECT_CHANNEL,
        payload: channel
    });

export const loadStylistData = async (stylist_id: string) => {
    try {
        const { data } = await Stylist.get(stylist_id);
        return data;
    } catch (e) {
        sentryException(e as Error, 'Cannot retrieive stylist data');
    }
};

export const clearInbox = (dispatch: Dispatch) =>
    dispatch({
        type: ActionTypes.CLEAR_INBOX_MESSAGES
    });
