import './style.scss';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Image, Nav, Navbar } from 'react-bootstrap';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';

import campaigns from '../../campaigns';
import { Page, StepsProgressBar } from '../../components';
import { logo } from '../../content';
import { onboarding } from '../../content';
import { Session, Style, Tracking } from '../../services';
import Formatter from '../../services/Formatter';
import Steps from './steps';
import { getUserId } from 'services/utils/user-utils';
import { useLocation } from 'react-router-dom';

const OnBoarding = ({
    steps,
    current,
    bodyTypes,
    styles,
    toggleModal,
    setMixpanelStore,
    history,
    setStep,
    gender,
    prevStep,
    styleIndex,
    prevStyle,
    stylist,
    resetOnboarding,
    flags,
    ldClient,
    user
}) => {
    const initialized = useRef(false);
    const [complete, setComplete] = useState(false);
    const location = useLocation();
    const currentStep = steps[current || 0];
    const { campaign } = Formatter.queryString(location.search);
    const { mpSource } = location.state || {};
    const campaignData = campaign && campaigns[campaign]?.onboarding[currentStep?.name];
    const stepData = { ...onboarding[currentStep?.name], ...campaignData };
    const StepComponent = Steps[currentStep?.component];

    const completeOnboarding = async () => {
        const userId = getUserId();

        if (!complete) {
            try {
                Tracking.facebook('trackCustom', 'completeOnboardingt', {}); // nice typo you've got there
                Tracking.google({
                    type: 'event',
                    event: 'Funnel',
                    data: { event_category: 'completeOnboarding' }
                });
                Tracking.google({
                    type: 'event',
                    event: 'conversion',
                    data: { send_to: 'AW-870964131/griOCPewnKcBEKO3p58D' }
                });

                if (userId) {
                    await Style.updateStyle(userId, {
                        gender,
                        tag_uuids: bodyTypes,
                        styles
                    });
                    const stylePreferredList = styles.reduce((acc, curr) => {
                        if (curr.rate === 2) acc.push(curr.name);
                        return acc;
                    }, []);
                    setMixpanelStore({
                        onboarding: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                    });
                    trackEvent({
                        name: MP_EVENTS.ONBOARDING_BRANDS_SELECTION,
                        properties: {
                            [MP_PROPS.PREFERRED_STYLES]: stylePreferredList
                        }
                    });
                    trackEvent({
                        name: MP_EVENTS.MEET_MY_MATCH,
                        properties: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                    });
                } else {
                    const stylePreferredList = styles.reduce((acc, curr) => {
                        if (curr.rate === 2) acc.push(curr.name);
                        return acc;
                    }, []);
                    setMixpanelStore({
                        onboarding: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                    });
                    trackEvent({
                        name: MP_EVENTS.MEET_MY_MATCH,
                        properties: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                    });
                    trackEvent({
                        name: MP_EVENTS.ONBOARDING_GENDER_SELECTION,
                        properties: {
                            [MP_PROPS.PREFERRED_STYLES]: stylePreferredList
                        }
                    });
                }

                setComplete(true);
            } catch (e) {
                sentryException(e, "Couldn't update user's quiz results");
            }
        }
    };

    const onComplete = useCallback(async () => {
        if (complete) {
            const userId = getUserId();
            const ldUser = ldClient?.getUser();

            if (user && userId) {
                let pathname = '';

                if (stylist?.uuid) {
                    if (stylist.has_active_session) {
                        pathname = `/chat/${stylist.uuid}/${userId}`;
                    } else {
                        pathname = '/goals';
                    }
                } else {
                    // This code is dependent on Launch Darkly user being identified
                    if (ldUser) {
                        let variant = 'control';

                        if (flags['skipStylistMatch']) {
                            const matches = await Style.getMatches(userId);

                            if (matches.data?.items?.length) {
                                const bestMatch = matches.data?.items
                                    .sort((a, b) => b.match_percentage - a.match_percentage)
                                    .shift();

                                pathname = `/stylist/${bestMatch.uuid}/profile`;
                                variant = 'stylist_profile';
                            } else {
                                variant = 'search';
                            }
                        } else {
                            pathname = '/stylistSearch';
                        }

                        trackEvent({
                            name: '$experiment_started',
                            properties: {
                                'Experiment name': 'match_skip',
                                'Variant name': variant
                            }
                        });
                    }
                }
                pathname && history.push({ pathname });
            } else {
                toggleModal({
                    type: 'Unlock',
                    data: { source: mpSource ?? MP_VALUES.ONBOARDING_QUIZ }
                });
            }
        }
    }, [complete, flags, user, stylist, toggleModal, history, ldClient, mpSource]);

    useEffect(() => {
        onComplete();
    }, [onComplete]);

    /*useEffect(() => {
        history.replace(currentStep ? `/onboarding/${currentStep.name}` : '/');
    }, [currentStep]);*/

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            Session.remove('onboarding');
            resetOnboarding();

            Tracking.tag({ event: 'ws.beginOnboardingQuiz' });
            trackEvent({
                name: MP_EVENTS.ONBOARDING_INTRO_VIEWS,
                properties: { [MP_PROPS.SOURCE]: mpSource }
            });
        }
    }, [resetOnboarding, mpSource]);

    return (
        <Page header={false} footer={false} fixedHeader={false}>
            <Container className="onboarding no-padding" fluid>
                <Navbar className="nav-bar">
                    {current > 1 && (
                        <Nav.Item
                            className="mobile-back d-flex d-sm-none"
                            onClick={() =>
                                styleIndex > 0 && currentStep.name === 'style'
                                    ? prevStyle(styleIndex)
                                    : prevStep()
                            }
                        />
                    )}

                    <Navbar.Brand href="/" className="logo">
                        <Image src={logo.black} className="d-flex d-sm-none" />
                        <Image src={logo.text_black} className="d-none d-sm-flex" />
                    </Navbar.Brand>

                    <Nav.Item className="mobile-step-counter d-flex d-sm-none">
                        Step {current + 1} out of {steps.length}:{' '}
                        <strong>{steps[current].text}</strong>
                    </Nav.Item>
                </Navbar>

                <StepsProgressBar
                    steps={steps}
                    onClick={(step) => (step < current ? setStep(step) : null)}
                    onBack={() =>
                        styleIndex > 0 && currentStep.name === 'style'
                            ? prevStyle(styleIndex)
                            : prevStep()
                    }
                    current={current - 0.99}
                    animated={true}
                />

                <StepComponent data={stepData} completeOnboarding={completeOnboarding} />
            </Container>
        </Page>
    );
};

export default withLDConsumer()(OnBoarding);
