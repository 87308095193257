import axios from 'axios';

import Config from './Config';
import { getUserId, getUserToken } from 'services/utils/user-utils';

const proxyRoot = Config.get('apiroot2');
const gwRoot = Config.get('api-gw');

const Style = {
    urls: {
        getMatches: (user_id) => `${proxyRoot}user/${user_id}/bestMatchesNew`
    },

    getMoodBoards: (gender) =>
        axios.get(`${proxyRoot}user/onboarding/moodboards`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),

    getBodyTypes: (gender) =>
        axios.get(`${proxyRoot}tag/active`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),

    updateStyle: (user_id, style) =>
        axios.put(
            `${proxyRoot}user/${user_id}/stylingPreferences`,
            { ...style },
            { headers: { token: getUserToken() } }
        ),

    getMatches: (user_id) =>
        axios.get(`${proxyRoot}user/${user_id}/bestMatchesNew`, {
            headers: { token: getUserToken() }
        }),
        
    // getResultsCount: params => axios.get(`${Config.get('apiroot2')}stylist/filters`, { params }),
    getFeed: (params) =>
        axios.get(`${proxyRoot}feed/all`, {
            params: { ...params, types: 'styled_you' }
        }),

    getOutfit: (outfit_uuid) => 
        axios.get(`${proxyRoot}outfit/web/${outfit_uuid}/${getUserId() ?? 'null'}`),

    getOutfits: (user_uuid, stylist_uuid) =>
        axios.get(`${proxyRoot}outfit/list/web/paging/${user_uuid}/${stylist_uuid}`, {
            params: { outfits: 1, from: 1, count: 50 },
            headers: { token: getUserToken() }
        }),

    getItem: (item_uuid) =>
        axios.get(`${proxyRoot}item/catalog/${item_uuid}`, { params: { user_uuid: getUserId() } }),
    
    getSizes: (item_uuid) =>
        axios.get(`${proxyRoot}item/${item_uuid}/availableSizes`, {
            headers: { token: getUserToken() }
        }),

    filters: () => axios.get(`${Config.get('apiroot2')}giftItem/filters?client=web`),
    giftItems: (filters) => axios.get(`${Config.get('apiroot2')}giftItem`, { params: filters }),
    getRequest: (request_uuid) =>
        axios.get(`${Config.get('apiroot2')}styleRequest/retrieve/${request_uuid}`, {
            headers: { token: getUserToken() }
        }),
    tags: (gender, type) =>
        axios.get(`${Config.get('apiroot2')}tag/active`, {
            params: { gender, type },
            headers: { token: getUserToken() }
        }),
    events: () =>
        axios.get(`${Config.get('apiroot2')}event`, { headers: { token: getUserToken() } })
};

export default Style;
