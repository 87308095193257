import './style.scss';

import { Elements, useStripe } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
import { useIsMobile } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { getOutfitProperties } from 'services/mixpanel/utils';
import { isShopableItem } from 'services/utils/item-utils';
import { itemToProps } from 'services/utils/mixpanel-utils';

import { Lightbox, Loader, Page, PaymentRequestButton } from '../../components';
import { item as texts } from '../../content.json';
import { Formatter, Payments, User } from '../../services';
import {
    BillingDetails,
    CustomWindow,
    ItemsCheckout,
    ItemType,
    ModalType,
    OutfitType
} from '../../types/item';
import { Client, Stylist } from '../../types/user';
import ItemDetails from './ItemDetails';
import MoreItems from './MoreItems';
import { getUserId } from 'services/utils/user-utils';

//const stripePromise = loadStripe(Payments.getStripeKey() as string);

declare const window: CustomWindow;

interface IItem {
    styleLoading: boolean;
    user: Client | Stylist;
    outfit: OutfitType;
    item: ItemType;
    loadOutfit: (uuid: string) => void;
    loadItem: (uuid: string) => void;
    updateItem: (item: ItemType | undefined) => void;
    updatePaymentToken: (token: string) => void;
    updateBillingDetails: (data: BillingDetails) => void;
    getBillingDetails: () => void;
    billingDetails: BillingDetails;
    paymentResult: any;
    cartTotal: number;
    checkoutPay: (data: ItemsCheckout) => void;
    clearResult: () => void;
    setPrice: (price: string) => void;
    addToCart: (item: ItemType, source: string) => void;
    removeFromCart: (item: ItemType) => void;
    toggleModal: (data: { type: string; url: string; data: { source: string } }) => void;
    location: any;
    history: any;
    currentItem: string;
    setCurrentItem: (item: string) => void;
}

const Item: React.FC<IItem> = ({
    styleLoading,
    user,
    outfit,
    item,
    loadOutfit,
    loadItem,
    updateItem,
    updatePaymentToken,
    updateBillingDetails,
    getBillingDetails,
    billingDetails,
    paymentResult,
    cartTotal,
    checkoutPay,
    clearResult,
    setPrice,
    addToCart,
    removeFromCart,
    toggleModal,
    location,
    history,
    currentItem,
    setCurrentItem
}) => {
    const { outfit_uuid, item_uuid } = useParams<{ outfit_uuid: string; item_uuid: string }>();
    const stripe = useStripe();
    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState('feed');
    const [source, setSource] = useState('');
    const [mainItem, setMainItem] = useState<ItemType>();
    const [sizes, setSizes] = useState<string[] | undefined>([]);
    const [size, setSize] = useState<string>();
    const [itemPictures, setItemPictures] = useState<string[]>([]);
    const [lightbox, setLightbox] = useState(false);
    const [showStripeButton, setShowStripeButton] = useState(false);
    const sliderRef = useRef<Slider>(null);
    const moreItems = useMemo(
        () => outfit?.items?.filter(({ uuid }) => uuid !== item_uuid) || [],
        [outfit, item_uuid]
    );
    const isMobile = useIsMobile();
    const priceFormatter = (price: string) => {
        if (typeof price !== 'string') price = JSON.stringify(price);
        return price ? JSON.stringify(parseInt(price.replace(',', ''), 10)) : price;
    };
    const itemContext = Formatter.queryString(location.search).context;
    const isGift = itemContext === 'gifts';
    const itemSource = itemContext !== 'feed' ? itemContext : 'feed';

    const slickMove = (ref: React.RefObject<Slider>) => ref?.current?.slickGoTo(0);
    const itemCheckoutEvent = () => {
        trackEvent({
            name: MP_EVENTS.ITEM_CHECKOUT_CLICKS,
            properties: itemToProps(item.item_uuid, itemSource, item.brand_name)
        });
    };
    useEffect(() => {
        if (Formatter.queryString(location.search).source)
            setSource(Formatter.queryString(location.search).source);
        if (item_uuid) loadItem(item_uuid);
        if (outfit_uuid) loadOutfit(outfit_uuid);
        if (history.location) {
            const path = history.location.pathname.split('/')[1];
            setContext(path === 'item' ? '' : path);
        }
        clearResult();
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.item = item;
        if (item.price) setPrice(priceFormatter(item.price.toString()));
        if (item?.sizes) setSizes(item.sizes.filter((size) => size.length > 0));
    }, [item]);

    useEffect(() => {
        if (user && user.user_uuid) {
            window.user_uuid = user.user_uuid;
            getBillingDetails();
        }
    }, [user]);

    useEffect(() => {
        window.billing = billingDetails;
    }, [billingDetails]);

    useEffect(() => {
        if (sliderRef.current) {
            slickMove(sliderRef);
            if (Array.isArray(item?.sizes))
                setSizes(item?.sizes.filter((size: string | string[]) => size.length > 0));
            setPrice(priceFormatter(item.price));
        }

        if (mainItem?.additional_pictures)
            setItemPictures([mainItem.picture, ...mainItem.additional_pictures]);
        else if (mainItem?.picture) setItemPictures([mainItem.picture]);
    }, [mainItem]);

    useEffect(() => {
        if (
            (outfit.unique === outfit_uuid ||
                outfit.unique === Formatter.queryString(location.search).outfit_uuid) &&
            outfit.items
        ) {
            setLoading(true);
            const outfitItem = outfit.items.find((it) => it.unique === item_uuid);
            item = { ...item, type: outfitItem?.type, item_type: outfitItem?.item_type };
            trackEvent({
                name: MP_EVENTS.LOOK_VIEWS,
                properties: getOutfitProperties(outfit, itemSource)
            });
            if (item) {
                if (item.item_uuid && currentItem !== item.item_uuid) {
                    setCurrentItem(item.item_uuid);

                    trackEvent({
                        name: MP_EVENTS.ITEM_VIEWS,
                        properties: itemToProps(item.item_uuid, itemSource, item.brand_name)
                    });
                }
                setMainItem({
                    ...item,
                    retail_price: priceFormatter(
                        typeof (item?.retail_price == 'string')
                            ? item?.retail_price
                            : item?.retail_price.toString()
                    ),
                    sale_price: priceFormatter(item?.sale_price?.toString() ?? ''),
                    isGift
                });
                setLoading(false);
            }
            updateItem(item);
            setLoading(false);
        }
    }, [outfit]);

    useEffect(() => {
        setLoading(true);
        if (item.uuid) {
            setMainItem({
                ...item,
                retail_price: priceFormatter(
                    typeof (item?.retail_price == 'string')
                        ? item?.retail_price
                        : item?.retail_price.toString()
                ),
                sale_price: priceFormatter(item?.sale_price?.toString() ?? ''),
                isGift
            });
            setLoading(false);
        }
    }, [item]);

    useEffect(() => {
        setLoading(false);
        if (paymentResult && paymentResult.payment) {
            history.push(
                `${context.length ? `/${context}` : context}${
                    outfit_uuid ? `/outfit/${outfit_uuid}` : ''
                }/item/${item_uuid}/confirmation`
            );
        }
    }, [paymentResult]);

    useEffect(() => {
        setLoading(styleLoading);
    }, [styleLoading]);

    const openItem = ({
        uuid,
        item_uuid,
        unique,
        sku
    }: {
        uuid: string;
        item_uuid: string;
        unique: string;
        sku: string | string[];
    }) => {
        const queryParams = location.search.length
            ? Object.keys(Formatter.queryString(location.search))
                  .map((key) => `${key}=${Formatter.queryString(location.search)[key]}`)
                  .join('&')
            : '';
        const itemId = uuid || item_uuid || unique || sku[0];
        loadItem(itemId);
        history.push(
            `/${context ? `${context}/` : ''}${
                outfit_uuid ? `outfit/${outfit_uuid}/` : ''
            }item/${itemId}${queryParams.length ? `?${queryParams}` : ''}`
        );
    };

    const openLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const onCartClick = (item: ItemType) => {
        if (!user) {
            toggleModal({ type: 'Signup', url: location.pathname, data: { source: 'cart click' } });
        } else {
            const action = item.is_in_cart ? removeFromCart : addToCart;
            action(item, itemSource);

            if (item.uuid === mainItem?.uuid) {
                setMainItem({
                    ...mainItem,
                    is_in_cart: !mainItem.is_in_cart
                });
            }
        }
    };

    const onCheckoutClick = () => {
        const url = `${context ? `/${context}` : ''}/${
            outfit_uuid ? `outfit/${outfit_uuid}/` : ''
        }item/${mainItem?.uuid ? mainItem?.uuid : mainItem?.unique}/checkout`;
        updateItem({ ...item, size });
        user
            ? history.push(url)
            : toggleModal({ type: 'Signup', url, data: { source: 'checkout click' } });
    };

    const stripeCheckout = async (token: string, data: Record<string, Record<string, string>>) => {
        const item_uuid = mainItem?.item_uuid;
        const stripeShipping = data.shippingAddress ? data.shippingAddress : undefined;
        let billing = window.billing;
        setLoading(true);
        updatePaymentToken(token);

        if (stripeShipping) {
            if (billing && !billing?.address) billing['address'] = {};
            billing = {
                address: {
                    city:
                        typeof stripeShipping === 'object'
                            ? stripeShipping?.city
                            : billing
                            ? billing?.address?.city
                            : '',
                    line1: stripeShipping?.addressLine
                        ? Array.isArray(stripeShipping.addressLine)
                            ? stripeShipping.addressLine.join(',')
                            : billing?.address
                            ? billing?.address.line1
                            : ''
                        : '',
                    country:
                        stripeShipping.country || billing?.address ? billing?.address?.country : '',
                    state: stripeShipping.region || billing?.address ? billing?.address?.state : '',
                    postal_code:
                        stripeShipping.postalCode || billing?.address
                            ? billing?.address?.postal_code
                            : ''
                },
                email: data?.payerEmail || billing ? billing?.email : '',
                name: stripeShipping.recipient
                    ? stripeShipping.recipient
                    : data?.payerName || billing
                    ? billing?.name
                    : '',
                phone: stripeShipping.phone
                    ? stripeShipping.phone
                    : data.payerPhone || billing
                    ? billing?.phone
                    : ''
            };
        }
        await User.updateBillingDetails({
            user_uuid: window.user_uuid,
            data: { billing_details: billing }
        });
        updateBillingDetails(billing ? billing : {});
        checkoutPay({ items: [{ uuid: item_uuid ?? '', size: window.size }] });
    };

    const onSizeChange = (e: React.FormEvent) => {
        window.size = (e.target as HTMLInputElement).value; // walkaround for stripe callback which looses context
        setSize((e.target as HTMLInputElement).value);
    };

    return (
        <Page footer={false} header={!isMobile} className="item-page">
            {loading ? (
                <Loader />
            ) : (
                <>
                    {lightbox && (
                        <Lightbox
                            variant="light"
                            opacity={1}
                            photos={itemPictures}
                            selected={0}
                            closePosition="right"
                            onClose={() => setLightbox(false)}
                        />
                    )}
                    <Container>
                        <Row className="header">
                            <Col>
                                {outfit && (
                                    <div
                                        className={`back-btn back-${
                                            context ? context : outfit_uuid ? 'outfit' : ''
                                        }`}
                                        onClick={() =>
                                            source
                                                ? history.push(`/${source?.split('_').join('/')}`)
                                                : outfit_uuid
                                                ? history.push(`/${context}/outfit/${outfit_uuid}`)
                                                : history.goBack()
                                        }
                                    />
                                )}
                                <Link to="/shopping-list" className="cart-link d-flex d-sm-none">
                                    <div className="cart-icon">
                                        {cartTotal > 0 ? cartTotal : ''}
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                        <Row className="item">
                            <Col xs={12} className="item-details d-block d-sm-none">
                                <Container fluid>{<ItemDetails item={mainItem} />}</Container>
                            </Col>
                            <Col xs={12} sm={6}>
                                {mainItem &&
                                    mainItem.has_sizes_information &&
                                    isShopableItem(mainItem) &&
                                    !mainItem?.is_in_closet && (
                                        <div
                                            className={`cart-icon ${
                                                mainItem?.is_in_cart ? 'added' : 'add'
                                            }`}
                                            onClick={() => onCartClick(mainItem)}
                                        />
                                    )}
                                {(mainItem?.uuid || mainItem?.unique) && (
                                    <Slider
                                        className={`item-carousel ${
                                            mainItem.additional_pictures &&
                                            mainItem.additional_pictures.length > 0
                                                ? 'with-dots'
                                                : ''
                                        }`}
                                        ref={sliderRef}
                                        dots={isMobile}
                                        arrows={!isMobile}
                                        infinite={false}
                                        speed={500}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        autoplay={false}
                                        adaptiveHeight={true}>
                                        {itemPictures.length > 0 ? (
                                            itemPictures.map((picture) => (
                                                <Image
                                                    key={picture}
                                                    src={picture}
                                                    onClick={() => !isMobile && setLightbox(true)}
                                                />
                                            ))
                                        ) : (
                                            <Image
                                                key={mainItem.picture}
                                                src={mainItem.picture}
                                                onClick={() => setLightbox(true)}
                                            />
                                        )}
                                    </Slider>
                                )}
                            </Col>
                            <Col xs={12} sm={6} className="item-details">
                                <Container fluid>
                                    <div className="d-none d-sm-block">
                                        {<ItemDetails item={mainItem} />}
                                    </div>
                                    <div>
                                        {mainItem?.has_sizes_information ? (
                                            <span>
                                                {mainItem?.color && (
                                                    <div className="color">
                                                        {texts.color}: <span>{mainItem.color}</span>
                                                    </div>
                                                )}

                                                <div className="sizes">
                                                    <Form.Control
                                                        as="select"
                                                        value={size || texts.selectSize}
                                                        onChange={(e) => onSizeChange(e)}>
                                                        <option key="default" disabled>
                                                            {texts.selectSize}
                                                        </option>
                                                        {sizes?.map((size) => (
                                                            <option key={size} value={size}>
                                                                {size}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </div>

                                                <div className="btns">
                                                    <Button
                                                        variant="dark"
                                                        disabled={!size}
                                                        onClick={() => {
                                                            onCheckoutClick();
                                                            itemCheckoutEvent();
                                                        }}>
                                                        {texts.checkout}
                                                    </Button>
                                                    {mainItem?.price && (
                                                        <PaymentRequestButton
                                                            show={showStripeButton}
                                                            stripe={stripe}
                                                            price={parseInt(mainItem?.price)}
                                                            onShow={() => setShowStripeButton(true)}
                                                            onSuccess={stripeCheckout}
                                                            props={{
                                                                height: 50,
                                                                stripeLabel: mainItem.name
                                                            }}
                                                            disabled={!size}
                                                            shipping={{
                                                                show: true,
                                                                price: async (
                                                                    billing: Record<
                                                                        string,
                                                                        BillingDetails
                                                                    >
                                                                ) => {
                                                                    const userId = getUserId();
                                                                    await User.updateBillingDetails(
                                                                        {
                                                                            user_uuid: userId,
                                                                            data: {
                                                                                billing_details: {
                                                                                    address:
                                                                                        billing[
                                                                                            'data'
                                                                                        ]
                                                                                }
                                                                            }
                                                                        }
                                                                    );
                                                                    const { data } =
                                                                        await Payments.checkoutList(
                                                                            {
                                                                                items: [
                                                                                    {
                                                                                        uuid: mainItem.item_uuid
                                                                                            ? mainItem.item_uuid
                                                                                            : mainItem.unique,
                                                                                        size
                                                                                    }
                                                                                ],
                                                                                user_uuid: userId
                                                                            }
                                                                        );
                                                                    return {
                                                                        ...data.data,
                                                                        item: mainItem
                                                                    };
                                                                }
                                                            }}
                                                            onClick={(
                                                                event: React.MouseEvent<HTMLElement>
                                                            ) => {
                                                                if (!user) {
                                                                    toggleModal({
                                                                        type: 'Signup',
                                                                        url: location.pathname,
                                                                        data: {
                                                                            source: 'payment request button'
                                                                        }
                                                                    });
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </span>
                                        ) : (
                                            <p className="out-of-stock d-block">
                                                {mainItem?.is_shoppable && texts.outOfStock}
                                            </p>
                                        )}

                                        {mainItem?.isGift &&
                                        mainItem?.stylist_name &&
                                        mainItem?.notes ? (
                                            <div className="why">
                                                <p className="title">
                                                    {texts.stylist.replace(
                                                        '%stylist%',
                                                        mainItem.stylist_name
                                                    )}
                                                </p>
                                                <p>{mainItem.notes}</p>
                                            </div>
                                        ) : (
                                            sizes &&
                                            sizes.length > 0 && (
                                                <div className="returns">{texts.returns}</div>
                                            )
                                        )}

                                        <div className="d-none d-sm-block">
                                            {sizes && sizes.length > 0 ? (
                                                <a
                                                    href={mainItem?.buy_url}
                                                    target="_blank"
                                                    className="link"
                                                    rel="noreferrer">
                                                    {mainItem?.buy_url_short &&
                                                        texts.shop.replace(
                                                            '%brand%',
                                                            mainItem?.buy_url_short
                                                        )}
                                                </a>
                                            ) : mainItem?.isGift ? (
                                                <p className="out-of-stock">{texts.outOfStock}</p>
                                            ) : (
                                                mainItem?.buy_url_short && (
                                                    <Button
                                                        variant="dark"
                                                        onClick={() => openLink(mainItem?.buy_url)}>
                                                        {texts.checkoutExternal.replace(
                                                            '%brand%',
                                                            mainItem.buy_url_short
                                                        )}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                    {mainItem?.isGift && mainItem?.tags && (
                                        <div className="tags">
                                            {mainItem?.tags.map((filter) => (
                                                <Badge variant="dark" key={filter}>
                                                    {filter}
                                                </Badge>
                                            ))}
                                        </div>
                                    )}
                                </Container>
                            </Col>
                            <Col xs={12}>
                                <div className="description">
                                    {mainItem?.description || mainItem?.shortDescription}
                                </div>

                                <div className="d-block d-sm-none">
                                    {sizes && sizes.length > 0 ? (
                                        <a
                                            href={mainItem?.buy_url}
                                            target="_blank"
                                            className="link"
                                            rel="noreferrer">
                                            {mainItem?.buy_url_short &&
                                                texts.shop.replace(
                                                    '%brand%',
                                                    mainItem?.buy_url_short
                                                )}
                                        </a>
                                    ) : mainItem?.isGift ? (
                                        <p className="out-of-stock d-none d-sm-block">
                                            {texts.outOfStock}
                                        </p>
                                    ) : (
                                        mainItem?.buy_url_short && (
                                            <Button
                                                variant="dark"
                                                onClick={() => openLink(mainItem?.buy_url)}>
                                                {texts.checkoutExternal.replace(
                                                    '%brand%',
                                                    mainItem.buy_url_short
                                                )}
                                            </Button>
                                        )
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {!!moreItems.length && (
                            <MoreItems items={moreItems} onClick={openItem} onCart={onCartClick} />
                        )}
                    </Container>
                    <div className="disclosure">{texts.disclosure}</div>
                </>
            )}
        </Page>
    );
};

export default (props: any) => <Item {...props} />;
