import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';

import { StickyButton, StyleSelector } from '../../../components';
import { Style as StyleSrv } from '../../../services';

const Style = ({
    gender,
    setStylesList,
    data: { question, answers, button },
    selectStyle,
    styleIndex,
    stylesList,
    styles,
    completeOnboarding,
    nextStyle
}) => {
    const buttonText = styleIndex === stylesList.length - 1 ? 'Meet my match' : button.skip;

    useEffect(() => {
        loadBoards();
    }, []);

    const loadBoards = async () => {
        const { data } = await StyleSrv.getMoodBoards(gender === 'neutral' ? 'female' : gender);
        setStylesList(data);
    };

    const onStyleSelect = (style) => {
        selectStyle(style);
    };

    useEffect(() => {
        if (
            styles.length === stylesList.length &&
            stylesList.length &&
            styleIndex === stylesList.length
        ) {
            completeOnboarding();
        }
    }, [stylesList, styles, styleIndex, completeOnboarding]);

    useEffect(() => {
        if (stylesList[styleIndex]) {
            trackEvent({
                name: MP_EVENTS.ONBOARDING_STYLE_VIEWS,
                properties: {
                    [MP_PROPS.PAGE_NAME]: stylesList[styleIndex].style
                }
            });
        }
    }, [stylesList, styleIndex]);

    return (
        <Row className="style">
            <Col className="content">
                <StyleSelector
                    styles={stylesList}
                    question={question}
                    answers={answers}
                    onSelect={onStyleSelect}
                    current={styleIndex}
                    selections={styles.reduce((ac, a) => ({ ...ac, [a.name]: a.rate }), {})}
                />
                {styles.length > 5 && (
                    <span>
                        <Button
                            variant="warning"
                            id="next"
                            className="d-none d-sm-flex"
                            onClick={() =>
                                styleIndex < stylesList.length - 1
                                    ? nextStyle(styleIndex)
                                    : completeOnboarding()
                            }>
                            {buttonText}
                        </Button>
                        <StickyButton
                            className="d-flex d-sm-none"
                            id="next"
                            onClick={() => {
                                trackEvent({
                                    name: MP_EVENTS.ONBOARDING_STYLE_SELECTION,
                                    properties: {
                                        [MP_PROPS.PAGE_NAME]: stylesList[styleIndex].style,
                                        [MP_PROPS.STYLE_SELECTION]: 'skip'
                                    }
                                });
                                styleIndex < stylesList.length - 1
                                    ? nextStyle(styleIndex)
                                    : completeOnboarding();
                            }}
                            text={buttonText}
                            type="warning"
                        />
                    </span>
                )}
            </Col>
        </Row>
    );
};

export default Style;
