import axios from 'axios';

import { Config } from '.';
import { getUserToken } from 'services/utils/user-utils';

interface Credentials {
    token?: string;
    facebook_token?: string;
    googleAuthCode?: string;
    email?: string;
    password?: string;
}

const UserAuth = {
    isAuth: () => !!getUserToken(),
    login: async (credentials: Credentials) => {
        try {
            if (credentials.token) {
                return UserAuth.tokenLogin(credentials);
            } else if (credentials.facebook_token) {
                return UserAuth.fbLogin(credentials);
            } else if (credentials.googleAuthCode) {
                return UserAuth.googleLogin(credentials);
            } else if (credentials.email) {
                return UserAuth.emailLogin(credentials);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailLogin: async (loginData: Credentials) => {
        if (loginData.email && loginData.password) {
            return axios.put(`${Config.get('api-gw')}login/email`, {
                client: `${Config.isMobile() ? 'mobile-' : ''}web`,
                email: loginData.email,
                password: loginData.password
            });
        }
    },
    tokenLogin: async ({ token }: Credentials) =>
        await axios.put(`${Config.get('api-gw')}login/token`, { token }),
    getToken: async () =>
        await axios.get(
            `${Config.get('apiroot2')}user/getToken/${Config.isMobile() ? 'mobile-' : ''}web`
        ),
    resetPassword: async (email: Credentials) =>
        await axios.post(
            `${Config.get('apiroot2')}user/resetPassword`,
            { email },
            { headers: { token: getUserToken() } }
        ),
    signup: async (credentials: Credentials) => {
        try {
            if (credentials.facebook_token) {
                return UserAuth.fbLogin(credentials);
            } else if (credentials.googleAuthCode) {
                return UserAuth.googleLogin(credentials);
            } else {
                return UserAuth.emailSignup(credentials);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    },
    emailSignup: async (credentials: Credentials) =>
        await axios.post(`${Config.get('api-gw')}signup/email`, credentials),
    fbLogin: async (credentials: Credentials) =>
        await axios.post(`${Config.get('api-gw')}login/facebook`, credentials),
    googleLogin: async ({ googleAuthCode }: Credentials) =>
        await axios.put(`${Config.get('api-gw')}login/google`, { authCode: googleAuthCode })
};

export default UserAuth;
